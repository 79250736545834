import React, { useContext, useEffect } from 'react';
import { KfHeroUtility } from '@klickinc/kf-react-components';
import AppContext from "@src/context"
// Utility imports
import Loadable from '@loadable/component';
import Seo from '@components/utility/SEO.jsx';
import Image from "@components/utility/Image";
import { withArtDirection, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { handleFBQ } from '@utils/FBQ';

import './styles.scss';
import DuchenneCommunitiesSpanish from '@components/page-sections/duchenne-communities/DuchenneCommunitiesSpanish';
const VideoPlayer = Loadable(() => import("@components/content/VideoPlayer.jsx"));

const Homepage = ({data}) => {
	const ctx = useContext(AppContext);

	const image_spanishBg = withArtDirection(getImage(data.spanishBgD), [
		{
			media: "(max-width: 640px)",
			image: getImage(data.spanishBgM),
		},
	]);
	const image_info1 = getImage(data.info1);
	const image_info2 = getImage(data.info2);
	const image_phone = getImage(data.phone);
	const image_ptcCares = getImage(data.ptcCares);
	const image_bgIcon1 = getImage(data.bgIcon1);
	const image_bgIcon2 = getImage(data.bgIcon2);
	const image_headerBlue = getImage(data.headerBlue);
	const image_headerBlueM = getImage(data.headerBlueM);
	const image_duchennePhoneIcon = getImage(data.duchennePhoneIcon);

	useEffect(() => {
		ctx.setPageClass('page--home-spanish');
	},[]);

	return(
		<>
			<section className='es-section-fold'>
				<div className='bg-1-image'>
					<Image
						imageData={image_spanishBg}
						alt=''
						loading='eager'
					/>
				</div>

				<div className="container container--inner es-section-fold--content">
					<KfHeroUtility addedClass='heading text-emflaza-white-100'>
						<span>Jugando como</span>
						<span className='h1-main'>Hermanos</span>
						<span>Porque pueden</span>
					</KfHeroUtility>

					<div className='sm:max-w-[25rem] space-y-5 md:max-w-[20rem] md:space-y-3 desktop:max-w-[25rem]'>
						<p className='desktop:text-lg desktop:leading-[24px]'>Para usted y su hijo, el presente lo es todo. Por eso, es el momento de hablar sobre EMFLAZA® (deflazacort), <span className='italic font-bold uppercase bg-emflaza-blue-50 text-emflaza-blue-600'>EL PRIMER</span> corticoesteroide aprobado por la Administración de Medicamentos y Alimentos (Food and Drug Administration, FDA) para niños con distrofia muscular de Duchenne (Duchenne muscular dystrophy, DMD).</p>
						<h2 className='text-3xl font-black uppercase md:text-[2rem] md:leading-[2rem] text-emflaza-white-100'>Ayude a prolongar<br /> <span className='ml-32 italic text-emflaza-blue-600 desktop:ml-24'>su presente</span>.</h2>
					</div>
				</div>
			</section>

			<section className='es-section--spanish-video'>
				<div className="container container--inner">
					<div className='max-w-3xl' id='haciendo-un-cambio'>
						<h3 className='pt-8 heading heading-hero-blue md:pt-12'>Haciendo un cambio: la historia de tratamiento de Christopher</h3>
						<p className='mb-5'>Sima cuida de su hijo cada día con la ayuda de su familia. Escuche cómo trabaja con la comunidad de DMD y <span>PTC&nbsp;<span className="italic">Cares</span>™</span> a lo largo del proceso.</p>
						<div className='video'>
							<VideoPlayer id={507119354} />
							<a href='/video-transcript/haciendo-un-cambio' target='_blank' rel="noreferrer noopener" className='gtm-transcript-cta transcript-cta'>Vea la Transcripción</a>
						</div>
					</div>
				</div>
			</section>

			<section className='es-section--what-you-should-know'>
				<div className='container container--inner'>
					<div className='max-w-3xl'>
						<h2 className="heading heading-hero-blue">
						¿Qué debería saber sobre los <span className='tracking-[-1px] sm:tracking-normal mobileMax:text-[28px]'>corticoesteroides?</span>
						</h2>
						<div className='mt-6 flex flex-col'>
							<div className='what-you-should-know--information-holder'>
								<Image
									imageData={image_info1}
									alt='Bienestar conductual, físico, funcional y emocional'
									loading='eager'
								/>
								<div className='what-you-should-know--information'>
									<div className='mb-10'>
										<h3 className='orange-heading mb-4'>Al vivir con DMD, su hijo tiene necesidades funcionales y emocionales únicas; ambas son consideraciones significativas a la hora de elegir un corticoesteroide.</h3>
										<p>Los corticoesteroides se introducen generalmente poco después del diagnóstico de DMD para aliviar la inflamación y preservar la fuerza muscular.</p>
									</div>
									<div className='mb-10'>
										<h3 className='orange-heading mb-4'>Cada niño es diferente, así que hay opciones a la hora de elegir un corticoesteroide.</h3>
										<p>Hay dos corticoesteroides que se utilizan para tratar a niños varones con DMD y no son iguales. Puede trabajar con su equipo de atención para comprender ambas opciones, sus indicaciones, beneficios, efectos secundarios y cómo pueden afectar a su hijo de forma diferente.</p>
									</div>
								</div>
							</div>
							<div className='cdc-guidelines'>
								<h3>La guía de los Centros para el Control y la Prevención de Enfermedades (Centers for Disease Control and Prevention, CDC) de EE. UU. recomienda un corticoesteroide como parte del plan de tratamiento de la DMD.</h3>
							</div>
							<div className='what-you-should-know--information-holder'>
								<Image
									imageData={image_info2}
									alt=''
									loading='eager'
								/>
								<div className='what-you-should-know--information'>
									<div>
										<p className='mb-10'>Obtenga información clara y concisa sobre los beneficios de EMFLAZA e información sobre <br className='sm:hidden'/> PTC <span className='italic'>Cares</span>™. Use la información de esta guía como ayuda cuando hable con el proveedor de atención médica de su hijo durante su próxima cita.</p>
										<a href='/folleto-para-cuidadores.pdf' target='_blank' rel="noreferrer noopener" className='kf-anchorlink link-btn link-btn--blue'>Descargar</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='es-section--ptc-cares'>
				<div className="container container--inner container--padded">
					<div className='max-w-3xl'>
						<div className='process'>
							<h2 className='kf-heroUtility heading heading-hero-blue'>Cómo empezar a usar ptc <span className='italic'>cares</span>™</h2>
							<div className='contact'>
								<div className='ptc-phone'>
									<div className='ptc-phone--content'>
										<p>Una vez que usted y el proveedor de atención médica de su hijo hayan decidido comenzar con EMFLAZA, simplemente completen juntos un <a href='/prescription-start-form.pdf' target="_blank" rel="noreferrer noopener" className='underline hover:no-underline focus:no-underline' onClick={() => handleFBQ('InitiateCheckout')}>formulario de inicio de receta</a> para comenzar el tratamiento. A partir de ahí, PTC <span className='italic'>Cares</span>™ gestiona cada paso del proceso de receta. Hay operadores de habla hispana a su disposición por si necesita ayuda.</p>
										<div className='flex my-8 flex-row'>
											<Image
												imageData={image_phone}
												alt=''
												loading='eager'
											/>
											<h2 className='self-end text-xl md:text-2xl font-bold text-emflaza-blue-200'>Llame al <a href='tel:18444782227' target="_blank" rel="noreferrer noopener" className='underline hover:no-underline focus:no-underline'>1-844-478-2227</a><br /> <span className='xl:whitespace-nowrap text-[0.85rem] sm:text-xl'>(Presione "2" para español)</span> <br/><span className='xl:whitespace-nowrap text-[0.85rem] sm:text-xl'>Lunes a viernes de 8&nbsp;<span className='text-sm'>AM</span>&nbsp;&#8211;&nbsp;6&nbsp;<span className='text-sm'>PM</span>,&nbsp; <br />hora del este</span></h2>
										</div>
									</div>
									<a href='https://ptccares.com/' target="_blank" rel='noopener noreferrer' className='visit-link link-btn link-btn--blue md:max-w-[18.75rem] md:mx-0 md:mr-auto'>Visite PTCCares.com</a>
								</div>
								<div className='ptc-cares-img'>
									<a href='https://ptccares.com/' target="_blank" rel='noopener noreferrer'>
										<Image
											imageData={image_ptcCares}
											alt='PTC Cares&trade;'
											loading='eager'
											className='max-w-14'
										/>
									</a>
								</div>
							</div>
							<h2 className='mb-5 text-base font-black uppercase md:text-lg text-emflaza-orange-100'>El proceso de receta</h2>
							<p>Cuando usted se inscribe en PTC <span className='italic'>Cares</span>, estará activando su recurso más valioso: un equipo de apoyo dedicado para su familia a lo largo de su tratamiento. PTC verificará su cobertura de seguro y trabajará con su farmacia especialista, además de revisar sus gastos de bolsillo.</p>
							<div className='flex flex-col md:flex-row items-start justify-between pt-20'>
								<div className='process--step'>
									<Image
										imageData={image_bgIcon1}
										alt='Uno'
										loading='eager'
									/>
									<h3 className='orange-heading'>Obtener una receta</h3>
									<p>Llene el <a className='underline hover:no-underline focus:no-underline' href='/prescription-start-form.pdf' target='_blank' rel="noreferrer noopener" onClick={() => handleFBQ('InitiateCheckout')}>Formulario de inicio de receta</a> con su profesional de atención médica.</p>
								</div>
								<div className='process--step'>
									<Image
										imageData={image_bgIcon2}
										alt='Dos'
										loading='eager'
									/>
									<h3 className='orange-heading'>Explicación de los beneficios</h3>
									<p>Su administrador de casos especial lo guiará a través de los detalles de los beneficios de su seguro y de cualquier otro programa de asistencia financiera que pueda estar a su disposición.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>

			<DuchenneCommunitiesSpanish data={{headerBlue: image_headerBlue, headerBlueM: image_headerBlueM, duchennePhoneIcon: image_duchennePhoneIcon}} />
		</>
	);
};

export default Homepage;


export const pageQuery = graphql`
  query {
	spanishBgM: file(relativePath: {eq: "pages/homepage-spanish/spanish-bg-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	spanishBgD: file(relativePath: {eq: "pages/homepage-spanish/spanish-bg.jpg"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	info1: file(relativePath: {eq: "pages/homepage-spanish/info-1-spanish.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	info2: file(relativePath: {eq: "pages/homepage-spanish/info-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	phone: file(relativePath: {eq: "pages/getting-started/5-0-phone.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	ptcCares: file(relativePath: {eq: "pages/getting-started/5-0-ptc-cares.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bgIcon1: file(relativePath: {eq: "pages/getting-started/bg-icon-1.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	bgIcon2: file(relativePath: {eq: "pages/getting-started/bg-icon-2.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	headerBlue: file(relativePath: {eq: "pages/resources/header-blue-bg.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	headerBlueM: file(relativePath: {eq: "pages/resources/header-blue-bg-mobile.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
	duchennePhoneIcon: file(relativePath: {eq: "icons/duchenne-phone-icon.png"}) {
		childImageSharp {
		  gatsbyImageData
		}
	},
}
`
export function Head() {
    return (
		<Seo
			title='Información en español - EMFLAZA® (deflazacort)'
			description='Ayude a prolongar su vida con EMFLAZA® (deflazacort). Aprende sobre los corticosteroides y cómo PTC Cares™ puede ayudar. Vea la información de prescripción completa y la información de seguridad importante.'
		/>
    );
};
