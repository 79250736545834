import PropTypes from "prop-types";
import React from 'react';
import { KfAnchorLink, KfHeroUtility } from '@klickinc/kf-react-components';
// Utility imports
import Image from '@components/utility/Image';

import './styles.scss';

const DuchenneCommunitiesSpanish = ({ data }) => {

    return (<>
        <section className='relative duchene-communities spanish'>
            <div className="container container--inner">
                <div className='max-w-3xl'>
                    <KfHeroUtility addedClass='heading heading-hero-blue pb-4'>
                        Comunidades relativas<br className='block sm:hidden' /> a duchenne
                    </KfHeroUtility>
                    <div className='max-w-xl'>
                        <p>Hay muchas organizaciones que trabajan para ayudar a las familias que conviven con la enfermedad de Duchenne.</p>
                    </div>
                    <div className='blue-header-bg relative mt-8'>
						<h2 className='relative z-10 text-center md:text-left text-emflaza-blue-600'>Conéctese con un<br className='block sm:hidden' /> Promotor Comunitario</h2>
                        <Image
                            imageData={data.headerBlue}
                            alt=''
                            loading='eager'
                        />
                        <Image
                            imageData={data.headerBlueM}
                            alt=''
                            loading='eager'
                        />
                    </div>
                    <div className='duchene-communities--program'>
                        <h2 className='orange-heading italic uppercase mb-6 mt-10'>
                            Programa de promotor comunitario
                        </h2>
                        <h3 className='blue-heading uppercase mb-2'>Hable con otros padres sobre emflaza y DMD</h3>
                        <p className='mb-6'>El programa de promotor comunitario lo puede contactar con otras familias quienes entienden su recorrido. Conocer cómo otros afrontaron los desafíos y lograron el éxito puede servir para ver otras perspectivas respecto a las decisiones que deba tomar para su familia. El Programa de Promotor Comunitario puede conectarlo con otras familias que comprenden su viaje. Su familia puede elegir hablar con promotores hispanohablantes o angloparlantes.</p>
                        <div className='program--contact flex flex-col md:flex-row items-center justify-between md:justify-evenly lg:justify-between py-5'>
                            <KfAnchorLink url='mailto:peernavigator@ptcbio.com' linkClass='contact--email link-btn link-btn--blue'>Correo electrónico</KfAnchorLink>
                            <p className='my-6'>o</p>
                            <div className='contact--phone flex flex-row items-center'>
                                <Image
									imageData={data.duchennePhoneIcon}
									alt=''
                                    loading='eager'
                                />
                                <p>Llame al <a href='tel:18662825873' target='_blank' className='underline hover:no-underline focus:no-underline' rel="noreferrer noopener">1-866-282-5873</a></p>
                            </div>
                        </div>
                    </div>

                    <div className='duchene-communities--communities-list'>

                        <div className='communities-list--row'>
                            <div className='communities-list--row-copy'>
                                <h3>The Akari Foundation</h3>
                                <p>Grupo dedicado a educar, empoderar y defender a la comunidad inmigrante hispana. Ayudan a conectar a las familias y proporcionan recursos en español sobre enfermedades raras, con especialización en la distrofia muscular de Duchenne (Duchenne Muscular Dystrophy, DMD).</p>
                            </div>
							<a href="https://theakarifoundation.org/inicio/" target='_blank' rel="noreferrer noopener" className='link-btn link-btn--blue'>Visite</a>
                        </div>

                        <div className='communities-list--row'>
                            <div className='communities-list--row-copy'>
                                <h3>Cureduchenne</h3>
                                <p>Esta organización sin fines de lucro apoya la investigación relacionada con Duchenne, y ofrece eventos, talleres y seminarios web en su sitio, que está disponible en español. Visite <a href="https://www.cureduchenne.org" target='_blank' rel="noreferrer noopener" className='underline hover:no-underline focus:no-underline'>www.cureduchenne.org</a> para obtener más información.</p>
                            </div>
							<a href="https://www.cureduchenne.org/language/spanish/" target='_blank' rel="noreferrer noopener" className='link-btn link-btn--blue'>Visite</a>
                        </div>

                        <div className='communities-list--row'>
                            <div className='communities-list--row-copy'>
                                <h3>Asociación de distrofia muscular (MDA)</h3>
                                <p>Hay más de 150 clínicas de atención de la Asociación de Distrofia Muscular (Muscular Dystrophy Association, MDA) en los Estados Unidos y Puerto Rico, y cada una alberga a un equipo completo de profesionales de atención médica. Para encontrar una clínica especializada de la MDA cerca de usted, visite <a href="https://www.mda.org/care/mda-care-centers" target='_blank' rel="noreferrer noopener" className='underline hover:no-underline focus:no-underline'>www.MDA.org/care/mda-care-centers</a> o llame al <a href='tel:1-800-572-1717' target="_blank" className='underline hover:no-underline focus:no-underline' rel="noreferrer noopener">1-800-572-1717</a>.</p>
                            </div>
							<a href="https://www.mda.org/search/site" target='_blank' rel="noreferrer noopener" className='link-btn link-btn--blue'>Visite</a>
                        </div>

                        <div className='communities-list--row'>
                            <div className='communities-list--row-copy'>
                                <h3>Centros de atención certificados por parent project muscular dystrophy (PPMD)</h3>
                                <p>Los centros de atención certificados por Parent Project Muscular Dystrophy (PPMD) brindan atención y servicios integrales específicamente para personas que viven con distrofia muscular de Duchenne. Estos centros se esfuerzan por brindar atención accesible e integral a la mayor cantidad de pacientes y familias posible. Los centros certificados por PPMD se pueden encontrar en <a href="https://www.parentprojectmd.org/carecenters" target='_blank' rel="noreferrer noopener" className='underline hover:no-underline focus:no-underline'>www.parentprojectmd.org/carecenters</a>. También se pueden encontrar materiales de apoyo y atención en español en <a href="https://www.parentprojectmd.org/care/care-and-support-materials/care-and-support-materials-spanish/" target='_blank' rel="noreferrer noopener" className='underline hover:no-underline focus:no-underline'>https://www.parentprojectmd.org/care/care-and-support-materials/care-and-support-materials-spanish/</a>.</p>
                            </div>
							<a href="https://www.parentprojectmd.org/care/find-a-certified-duchenne-care-center/" target='_blank' rel="noreferrer noopener" className='link-btn link-btn--blue'>Visite</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </>)
}

export default DuchenneCommunitiesSpanish;


DuchenneCommunitiesSpanish.propTypes = {
    data: PropTypes.object.isRequired,
}
